<script setup lang="ts">
import type { QueryBuilderParams } from '@nuxt/content'

const query: QueryBuilderParams = { path: '/pravni-sluzby', where: [{ navigation: { $ne: false } }] }
</script>

<template>
  <div class="not-prose grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    <ContentList v-slot="{ list }" :query="query">
      <NuxtLink
        v-for="(service, index) in list"
        :key="service._path"
        class="group flex flex-row items-center justify-start gap-4 border-b border-primary/10 py-4 text-black transition-colors duration-300 md:min-h-64 md:flex-col md:items-start md:justify-between md:border-r md:p-8 md:hover:bg-primary md:hover:text-white md:[&:nth-child(2n)]:border-r-0 lg:[&:nth-child(2n)]:border-r lg:[&:nth-child(3n)]:border-r-0 xl:[&:nth-child(3n)]:border-r xl:[&:nth-child(4n)]:border-r-0"
        :class="[
          {
            'border-b-0': index === list.length - 1,
            'md:border-b-0': index >= list.length - 2,
            'lg:border-b-0': index >= list.length - 3,
            'xl:border-b-0': index >= list.length - 4,
          },
        ]"
        prefetch-on="interaction"
        :to="service._path"
      >
        <div class="w-6 flex-none">
          <span class="pb-2 text-xl text-secondary transition-colors duration-300 md:text-base md:group-hover:text-white/80">{{ index + 1 }}</span>
        </div>

        <div class="flex flex-col gap-y-2">
          <span class="text-lg">{{ service.title }}</span>

          <span class="text-sm text-black/40 transition-colors duration-300 md:group-hover:text-white/40">{{ service.shortDescription }}</span>
        </div>

        <div class="ml-auto md:ml-0 md:mt-auto">
          <Icon
            class="text-secondary transition-colors duration-300 md:group-hover:text-white/80"
            name="carbon:arrow-up-right"
            size="1.75em"
          />
        </div>
      </NuxtLink>
    </ContentList>
  </div>
</template>
